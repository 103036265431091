import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

const lines = [
  "Reittä pitkin saatana",
  "Köytän sut kohta Oton pöytään ja syön vartalojugurttia",
  "Ei oo pakarasta kiinni saatana",
  "Mun suuhun mahtuu",
  'Sää katoit mun "pussia" sillä silmällä',
  "Miten sä syöt sun banaanijugurtin? Sä dippaat siihen nakkia",
  "No sun pusseja mää saan repiä",
  "Saakelin vittu",
  "EI SEIS",
  "Miksen mää saa tukkia sun laskimoita?",
  "Näyttäisin sulle yhen jutun, mut niistä on vaikea ottaa kuva vaatteet päällä",
  "Lähdetäänkö Marianne testaamaan meidän parisänky",
  "Siihen tulee tunteet jos siihen puhaltaa",
  "Sun kahvilla ei oo tunteita, sen sielu on musta",
  "Haluut sä nuolasta tätä",
  "Mullon niin paljon näytettävää sulle",
  "Mä oon suunnitellu tätä varten. Kato nyt aukes eka nappi.",
  "Oho täälläki on nappi auki",
  "Te ootte ihan kauheita ihmisiä",
  "Muna suuhun",
  "Mulla kesti tajuta, mut nyt mää tajusin",
  "Mää en tajua ku se tapahtuu, ja sit se on tuolla",
  "Just some random accidental goats",
  "You're gonna have to do it on top of the strings",
  "Vittu Pynnönen saatana",
  "Lähteeks Jonne panemaan",
  "Arto jää yksin tänne ku kaikki muut lähtee panee",
  "Makkara voi kyrvähtää",
  "En ees yrittäny, meni nenään silti",
  "Täältä tulee ihan pirusti ku tätä piiskaa",
  "Ihmiset, tai siis designerit",
  "Voitko hieroo mun takareittä",
  "Mää näytän sen sulle lähempää",
  "Onks sulla housut jalassa?",
  "Sieltä tulee kuitenkin musta mies, ja se ei puhu suomea",
  "Musta tulis niin hyvä salaliitootikko",
  "En osaa olla mulkku",
  "Mää puhallan sun sielun pois",
  "Mää vaan nielen sen kaiken",
  "Täti jakaa",
  "Mussa ei oo mitään muuta ghettoo ku booty",
  "Hei, ihan oikeita lauseita!",
  "Sattuu, mut takaperin ei satu",
  "Mullon aina jorma mielessä",
  "Mää oon ihan avopervo",
  "Ihan tavallinen jännitehysteriapurkaus",
  "Fuck, fuck, fuck, fuck, fuck, ei sinne!",
  "Mää tupsutin sen kaiken jo omaan naamaani",
  "Antti, onko sulla 46-tuumainen?",
  "Sitä pitää kattoo läheltä että näkee kaikki sateenkaaren värit",
  "Mää kyllä tykkään enemmän että siinä on se vittu, mutta silti...",
  "Antti voi lähteä Eijasta, mutta Eija ei voi lähteä Antista",
  "Minulla on erittäin siveelliset ritsikat tänään, jos sattuu vilahtamaan",
  "Antti has been bad...",
  "Black cocks",
  "Ei tiedä kumpi pitää syödä; Jonne vai kakku",
  "Hih, olen hilpeä",
  "En varmasti iske silmää sillä silmällä",
  "Oon innoissani kaikesta mistä oon innoissani",
  "Kun mää oon hiljaa ni alan ajatella ",
  "Höpönaattori",
  "Vehje kuin vehje",
  "Ei koolla väliä",
  "Musta tulis tosi hyvä setämies",
  "Siitä vaan rennosti peppuvakoon kädet",
  "Olen ähkypylly. Eiku naama. Eiku maha.",
  "Oispa pitkäperjantain aatto vapaa, niinku kaikki aatot",
  "Kato jos mää oon tälleen ja sää et kato mun jalkoja, ni mää oon vähän niinku merenneito",
  "Käkikin kukkuu",
  "Oon siideripiilossa",
  "Kaikilla pitäisi olla yksi Marianne",
  "Sää et nää mua ku mää oon tässä",
  "Olen sininen kana",
  "Mää osaan kyllä viihdyttää itseäni",
  "Marianne on niin kyllästetty valkosipulilla että se on muuttunut valkosipuliksi",
  "Mun tukka on sängynjalan alla",
  "Nai kokkia. Englanniksi fuck cocks.",
  "Pitää imee kovempaa ni sit se tulee",
  "Annatko sakset ja tuotko pyllys tänne",
  "Mää sylen sen sun suuhun, ota kiinni",
  "Se tuli ku sää ravistit sitä",
  "Eikö tuntunutkin kivalta läpsytellä lettua",
  "Onpas kiva olla ulkona kaapista. Hetetokaapista.",
  "Meidän tehtävä Teron kanssa on aiheuttaa sulle verisuonen pullistumista",
  "Jos ei jaksa keskittyä ku laittaa sen suuhun ni voi sattua",
  "Omaan nyt vihdoni ATK-taidot",
  "Oon vanhempi kun sä, ei johdu iästä, oot vaan heikko!",
];

const shuffle = (current = null) => {
  const num = Math.floor(Math.random() * lines.length);
  if (num === current) return shuffle(current);
  const line = lines[num];
  return {
    line,
    num,
  };
};

const Line = ({ match, history, ...rest }) => {
  const next = shuffle(match.params.id).num;
  const line = lines[match.params.id] || history.push(`/${lines.length - 1}`);
  return (
    <div className="App" onClick={() => history.push(`/${next}`)}>
      <header className="App-header">
        <p>
          <span className="line">{line}</span>
          <br />
          <span className="punchline">- Eija</span>
        </p>
      </header>
    </div>
  );
};

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path="/"
          render={() => <Redirect to={`/${shuffle().num}`} />}
        />

        <Route path="/:id" component={Line} />
      </React.Fragment>
    );
  }
}

export default App;
